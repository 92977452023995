function load()
{
    let el = document.querySelectorAll('[data-load]');
    if(el) {
        for (let i = 0; i < el.length; i++) {
            let loader = el[i];
            setTimeout(function () {
                loader.classList.add('loaded');
            }, 300);
        }
    }
}

document.addEventListener("DOMContentLoaded", load);
