import slick from 'slick-carousel';

let $promoSlider = $('.laser__slider');
let $quotesSlider = $('.offer__quotes-slider');
let $specialistsSlider = $('.specialists__list');
let $stocksInfoSlider = $('.stocks__info-list');
let $stocksImageSlider = $('.stocks__images-list');
let $stocksMobileSlider = $('.stocks__mobile-list');
let $gallerySlider = $('.about__gallery-list');
let $galleryMobileSlider = $('.about__gallery-mobile-list');

if ($promoSlider.length > 0) {
    $promoSlider.slick({
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        fade: true,
        cssEase: 'linear',
        infinite: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true
                }
            }
        ]
    });
    activateArrows($('[data-slider-arrow-promo]'), 'slider-arrow-promo', $promoSlider)
}

if ($quotesSlider.length > 0) {
    $quotesSlider.slick({
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        swipe: false,
        centerMode: false,
        autoplay: true,
        autoplaySpeed: 7000,
        fade: true,
        cssEase: 'linear',
        infinite: true,
    })
}

if ($specialistsSlider.length > 0) {
    $specialistsSlider.slick({
        arrows: false,
        slidesToShow: 3.7,
        slidesToScroll: 1,
        draggable: true,
        swipe: false,
        centerPadding: '',
        infinite: false,
        centerMode:false,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 3.5
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2.2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.5
                },
            },
        ]
    });
    //Активируем функционал стрелок
    //В параметры функции передаем блок стрелок через дата атрибут, название дата атрибута, элемент сладера
    activateArrows($('[data-slider-arrow-specialists]'), 'slider-arrow-specialists', $specialistsSlider);
}

if ($stocksInfoSlider.length > 0) {
    $stocksInfoSlider.slick({
        arrows: false,
        swipe: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    activateArrows($('[data-slider-arrow-stocks]'), 'slider-arrow-stocks', $stocksInfoSlider);
}

if ($stocksImageSlider.length > 0) {
    $stocksImageSlider.slick({
        arrows: false,
        swipe: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    activateArrows($('[data-slider-arrow-stocks]'), 'slider-arrow-stocks', $stocksImageSlider);
}

if ($stocksMobileSlider.length > 0) {

    $stocksMobileSlider.slick({
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    activateArrows($('[data-slider-arrow-stocks]'), 'slider-arrow-stocks', $stocksMobileSlider);
}

if ($gallerySlider.length > 0) {
    $gallerySlider.slick({
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '400px',
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '270px',
                }
            }
        ]
    });

    activateArrows($('[data-slider-arrow-gallery]'), 'slider-arrow-gallery', $gallerySlider)
}

if ($galleryMobileSlider.length > 0) {
    $galleryMobileSlider.slick({
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '100px',
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                }
            }
        ]
    })

    activateArrows($('[data-slider-arrow-gallery]'), 'slider-arrow-gallery', $galleryMobileSlider)
}


function activateArrows($elem, $data, slider) {
    $elem.on('click', function (e) {
        e.preventDefault();
        if ($(this).data($data) === 'left') {
            slider.slick('slickPrev');
        } else if ($(this).data($data) === 'right') {
            slider.slick('slickNext');
        }
    })
}