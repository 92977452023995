require('../components/sticky/sticky.js');
require('../components/sticky/sticky_more.js');

let services = $('.services__sections');
let images = $('.services__img');


function checkSticky() {
    if ($(document).width() >= 1200 && !services.hasClass('.services__sections')){
        constructSticky();
    }
}

function constructSticky() {
    images.psticky({
        parent: '.services__right',
        top: 0,
    });
    services.pstickyMore({
        parent: '.services__left',
        top: 0,
    });
}


setTimeout(function () {
    checkSticky();
}, 1000);


$('.services__tab-link').on('click', function () {
    if ($(document).width() >= 1200) {
        $('html, body').animate({
            scrollTop: $(".services__main").offset().top
        }, 400);
    }
    $('.services__img').attr('src', $(this).data('image'));
});