require('../components/sticky/sticky.js');

let hamburger = document.querySelectorAll('.header__hamburger');
let body = document.querySelector('body');
let link = document.querySelectorAll('.header__link');
let header = document.querySelector('.header');
let headerSticky = $('.header__sticky');
let parent = $('#wrapper');

if (window.innerWidth < 1200) {
    if(header) {
        for(let i = 0; i < hamburger.length; i++) {
            hamburger[i].addEventListener("click", function (e) {
                e.preventDefault();
                if (header.classList.contains('header_active')) {
                    header.classList.remove('header_active');
                    body.classList.remove('body_hidden');
                } else {
                    body.classList.add('body_hidden');
                    header.className += " header_active";
                }
            })
        }
    }

    for(let i = 0; i < link.length; i++) {
        link[i].addEventListener("click", function () {
            if (header.classList.contains('header_active')) {
                header.classList.remove('header_active');
                body.classList.remove('body_hidden');
            }
        });
    }

    constructSticky();
}

function constructSticky() {
    headerSticky.psticky({
        top: 0,
        width: '100%',
        parent: parent
    });
}
