let wrapper = document.querySelector('body');

if (wrapper && window.screen.availWidth >= 1200) {
    wrapper.addEventListener('mousemove', moveMouseParallax);

    function moveMouseParallax(e) {
        let elements = document.querySelectorAll('[data-parallax-move]');
        let halfWidth = window.innerWidth / 2;
        let halfHeight = window.innerHeight / 2;

        let offsetX = (halfWidth - e.pageX);
        let offsetY = (halfHeight - e.pageY);

        elements.forEach((elem, index) => {
            let strafe = elem.dataset.depth * 4;
            let x = offsetX / strafe;
            let y = offsetY / strafe;
            if (y < -25) {
                y= -25
            }
            let transformStrings = `translate3d(${x}px, ${y}px, 0)`;
            elem.style.transform = transformStrings;

        });
    }
}



window.addEventListener('scroll', scrollParallax);

function scrollParallax() {
    let elements = document.querySelectorAll('[data-parallax]');
    elements.forEach((elem) => {
        let posElemScreen = elem.getBoundingClientRect().bottom;
        if (posElemScreen > 0 && posElemScreen < window.innerHeight+elem.clientHeight ) {
            let strafe = elem.dataset.speed;
            let offset = (window.innerHeight - posElemScreen) * strafe/ 10;
            elem.style.transform = `translate3d(0, -${offset}px, 0)`;
        }
    })
}







